// 
// right-sidebar.scss
//

.right-bar {
    background-color: $white;
    box-shadow: 0 0 24px 0 rgba($dark, 0.1), 0 1px 0 0 rgba($dark, 0.08);
    display: block;
    position: fixed;
    transition: all 200ms ease-out;
    width: $rightbar-width;
    z-index: 9999;
    float: right !important;
    right: -($rightbar-width + 10px);
    top: 0;
    bottom: 0;
    
    .rightbar-content {
        padding-bottom: 70px;
    }

    .rightbar-title {
        background-color: $dark;
        padding: 27px 25px;
        color: $white;
    }
    .right-bar-toggle {
        background-color: lighten($dark, 7%);
        height: 24px;
        width: 24px;
        line-height: 24px;
        color: $white;
        text-align: center;
        border-radius: 50%;
        margin-top: -4px;
        &:hover {
            background-color: $primary;
        }
    }
}

.right-bar-enabled {
    .right-bar {
        right: 0;
    }
}

// Timeline
.timeline-alt {
    padding: 20px 0;
    position: relative;
    &:before {
        background-color: $light;
        bottom: 30px;
        content: "";
        left: 9px;
        position: absolute;
        top: 30px;
        width: 2px;
        z-index: 0;
    }
    .timeline-item {
        position: relative;
        .timeline-icon {
            float: left;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            border: 2px solid transparent;
            font-size: 12px;
            text-align: center;
            line-height: 16px;
            background-color: $white;
        }
        .timeline-item-info {
            margin-left: 30px;
        }
    }
}

@include media-breakpoint-down(sm) {
    .right-bar {
        overflow: auto;
        .slimscroll-menu {
            height: auto !important;
        }
    }
}