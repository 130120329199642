//
// simplemde.scss
//

.editor-toolbar.fullscreen,.CodeMirror-fullscreen {
  z-index: 1000;
}

.editor-preview, .editor-preview-side {
  background: $gray-100;
}

.editor-toolbar {
  border: 1px solid $gray-400;
  border-bottom: 0;
}

.CodeMirror {
  border: 1px solid $gray-300;
}