//
// dropzone.scss
//

.dropzone {
    border: 2px dashed rgba($dark, 0.3);
    background: $white;
    border-radius: 6px;
    cursor: pointer;
    min-height: 150px;
    padding: 20px;

    .dz-message {
        text-align: center;
        margin: 2rem 0;
    }
    &.dz-started {
        .dz-message {
            display: none;
        }
    }
}