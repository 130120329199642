// 
// page-title.scss
//

.page-title-box {
    .page-title {
        font-size: 18px;
        margin: 0;
        line-height: 85px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .page-title-right {
        float: right;
        margin-top: 25px;
    }

    .breadcrumb {
        padding-top: 8px;
    }
}

@include media-breakpoint-down(sm) {
    .page-title-box {
        .page-title {
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 70px;
        }
        .breadcrumb {
            display: none;
        }
        .page-title-right {
            display: none;
        }
    }
}

@media (max-width: 419px) {
    .page-title-box .breadcrumb {
        display: none;
    }
}